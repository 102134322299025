@font-face {
    font-family: 'matter';
    src: url($cdn-fonts + '/matter/Matter-Light.woff2') format('woff2'),
         url($cdn-fonts + '/matter/Matter-Light.woff') format('woff');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

/*
@font-face {
    font-family: 'matter';
    src: url($cdn-fonts + '/matter/Matter-Regular.woff2') format('woff2'),
         url($cdn-fonts + '/matter/Matter-Regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
} */

@font-face {
    font-family: 'matter';
    src: url($cdn-fonts + '/matter/Matter-Bold.woff2') format('woff2'),
         url($cdn-fonts + '/matter/Matter-Bold.woff') format('woff');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'matter';
    src: url($cdn-fonts + '/matter/Matter-Heavy.woff2') format('woff2'),
         url($cdn-fonts + '/matter/Matter-Heavy.woff') format('woff');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}