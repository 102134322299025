@font-face {
    font-family: 'graphik';
    src:  url($cdn-fonts + '/graphik/Graphik-Thin.woff2') format('woff2'),
          url($cdn-fonts + '/graphik/Graphik-Thin.woff') format('woff');
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

/* @font-face {
    font-family: 'graphik';
    src:  url($cdn-fonts + '/graphik/Graphik-Light.woff2') format('woff2'),
          url($cdn-fonts + '/graphik/Graphik-Light.woff') format('woff');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'graphik';
    src:  url($cdn-fonts + '/graphik/Graphik-LightItalic.woff2') format('woff2'),
          url($cdn-fonts + '/graphik/Graphik-LightItalic.woff') format('woff');
    font-style: italic;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'graphik';
    src:  url($cdn-fonts + '/graphik/Graphik-Regular.woff2') format('woff2'),
          url($cdn-fonts + '/graphik/Graphik-Regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'graphik';
    src:  url($cdn-fonts + '/graphik/Graphik-Medium.woff2') format('woff2'),
          url($cdn-fonts + '/graphik/Graphik-Medium.woff') format('woff');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'graphik';
    src:  url($cdn-fonts + '/graphik/Graphik-Semibold.woff2') format('woff2'),
          url($cdn-fonts + '/graphik/Graphik-Semibold.woff') format('woff');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'graphik';
    src:  url($cdn-fonts + '/graphik/Graphik-Bold.woff2') format('woff2'),
          url($cdn-fonts + '/graphik/Graphik-Bold.woff') format('woff');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'graphik';
    src:  url($cdn-fonts + '/graphik/Graphik-Black.woff2') format('woff2'),
          url($cdn-fonts + '/graphik/Graphik-Black.woff') format('woff');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
} */