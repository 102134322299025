/* @font-face {
    font-family: 'sora';
    src:  url($cdn-fonts + '/sora/Sora-Thin.woff2') format('woff2'),
          url($cdn-fonts + '/sora/Sora-Thin.woff') format('ttf');
    font-style: normal;
    font-weight: 200;
    font-display: swap;
} */

@font-face {
    font-family: 'sora';
    src:  url($cdn-fonts + '/sora/Sora-Light.woff2') format('woff2'),
          url($cdn-fonts + '/sora/Sora-Light.woff') format('ttf');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

/*
@font-face {
    font-family: 'sora';
    src:  url($cdn-fonts + '/sora/Sora-Regular.woff2') format('woff2'),
          url($cdn-fonts + '/sora/Sora-Regular.woff') format('ttf');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'sora';
    src:  url($cdn-fonts + '/sora/Sora-SemiBold.woff2') format('woff2'),
          url($cdn-fonts + '/sora/Sora-SemiBold.woff') format('ttf');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
} */

@font-face {
    font-family: 'sora';
    src:  url($cdn-fonts + '/sora/Sora-Bold.woff2') format('woff2'),
          url($cdn-fonts + '/sora/Sora-Bold.woff') format('ttf');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'sora';
    src:  url($cdn-fonts + '/sora/Sora-ExtraBold.woff2') format('woff2'),
          url($cdn-fonts + '/sora/Sora-ExtraBold.woff') format('ttf');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}